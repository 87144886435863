import axios from 'axios';
import CryptoJS from 'crypto-js';


const useRequestsWOAccessToken = () => {

    
  // const res = await getRequestWOAccessToken('api-name');
  // if (res && res.code === 200) {
  //   setData(res.data);
    
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'success',
  //     message: res.message,
  //   }));
  // }
  // else {
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'error',
  //     message: res.message,
  //   }));
  // }
  // resetAlertData();
  // Above text is an example of below function how to call below function
  const getRequestWOAccessToken = async (apiName) => {
    const headers = {
      'Content-Type': 'application/json',
      'bcbqtoken': process.env.REACT_APP_TOKEN_SECRET,
      'lang': localStorage.getItem("I18N_LANGUAGE"),
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}${apiName}`,
        { headers: headers }
      );

      const res = response.data;

      if (res.code === 200) {
        if(res.data) {
            const bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_USER_TOKEN_SECRET);
            const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: originalText,
            };
        }
        else {
            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: [],
            };
        }
      } else {
        return {
            code: res.code,
            status: res.status,
            message: res.message,
            data: [],
        };
      }
    } catch (error) {
      console.error("Error: ", error);
      return {
            code: 401,
            status: 'ERROR',
            message: error.message,
            data: [],
      };
    }
  };

  // const jsonpar = JSON.stringify({
  //   old_password: values.old_password,
  //   new_password: values.new_password,
  //   new_confirm_password: values.new_confirm_password,
  // });
  // const res = await postRequestWOAccessToken('api-name', jsonpar);
  // if (res && res.code === 200) {
  //   setData(res.data);
    
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'success',
  //     message: res.message,
  //   }));
  // }
  // else {
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'error',
  //     message: res.message,
  //   }));
  // }
  // resetAlertData();
  // Above text is an example of below function how to call below function
  const postRequestWOAccessToken = async (apiName, jsonpar) => {
    const headers = {
      'Content-Type': 'application/json',
      'bcbqtoken': process.env.REACT_APP_TOKEN_SECRET,
      'lang': localStorage.getItem("I18N_LANGUAGE"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}${apiName}`,
        { data: CryptoJS.AES.encrypt(jsonpar, process.env.REACT_APP_USER_TOKEN_SECRET).toString() },
        { headers: headers }
      );

      const res = response.data;

      if (res.code === 200) {
        if(res.data) {
            const bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_USER_TOKEN_SECRET);
            const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: originalText,
            };
        }
        else {
            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: [],
            };
        }
      } else {
        return {
            code: res.code,
            status: res.status,
            message: res.message,
            data: [],
        };
      }
    } catch (error) {
      console.error("Error: ", error);
      return {
            code: 401,
            status: 'ERROR',
            message: error.message,
            data: [],
      };
    }
  };

  return { getRequestWOAccessToken, postRequestWOAccessToken };
};

export default useRequestsWOAccessToken;