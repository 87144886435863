import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Modal } from "reactstrap";

// Formik Validation
import { withRouter, Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import "../../assets/scss/custom/style.css";

import moment from 'moment';
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const Dividends = () => {
  //meta title
  document.title = i18next.t('Dividends PG document title');
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const [Transaction, setTransaction] = React.useState([]);
  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async (elementData) => {
    setTransaction(elementData);
    setStandardModal1(true);
  }
  
  const [TransactionTBody, setTransactionTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{moment(element.record_date).format('YYYY-MM-DD')}</td>
        <td>{moment(element.payment_date).format('YYYY-MM-DD')}</td>
        <td>{element.number_of_token}</td>
        <td>${element.dividend_amount}</td>
        {element.paid_amount !== null?
          <td>{element.paid_amount} &nbsp;{element.paid_amount_currency}</td>
        : <td>-</td> }
        <td>{element.payment_method}</td>
        <td><span className={"font-size-14 badge bg-secondary rounded-pill " + (element.status === 'Pending' ? 'badge-soft-warning' : element.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{element.status}</span></td>
        {element.payment_detail !== null?
          <td style={{ whiteSpace: 'pre-line' }}>{element.payment_detail}</td>
        : <td>-</td> }
        <td className="text-center">
            <button type="button" className="btn-sm btn btn-primary" onClick={(e)=>togStandardModal1(element)}><i className="mdi mdi-eye"></i></button>
        </td>
      </tr>
    ));

    setTransactionTBody(tempTBody);
  }
  const handleLoadGetTransactionData = async () => {
    const res = await getRequest('get-dividend-transaction-data');
    if (res && res.code === 200) {
      handleT1Body(res.data);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="9" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setTransactionTBody(tempTBody);
    }
  }
  React.useEffect(() => {
    handleLoadGetTransactionData();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('PG breadcrumbItem Dividends')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends" className="active"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">

                <Row>
                  <Col lg="12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table mb-0" style={{minWidth: '1500px'}}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('Record Date')}</th>
                                <th>{i18next.t('Payment Date')}</th>
                                <th>{i18next.t('Number of Token')}</th>
                                <th>{i18next.t('Dividend Amount')}</th>
                                <th>{i18next.t('Amount to be Paid')}</th>
                                <th>{i18next.t('Payment Method')}</th>
                                <th>{i18next.t('Status')}</th>
                                <th>{i18next.t('Payment Detail')}</th>
                                <th className="text-center">{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TransactionTBody}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Fiat Transaction Modal */}

        <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" size="lg">
          <div className="modal-header">
            <h5 className="modal-title mt-0">{i18next.t('Payment Reference')}</h5>
            <button type="button" onClick={(e)=>setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body mb-3">
            <div className="row">
              <div className="col-lg-6">
                <p className="mb-1 border-box-1"><b>{i18next.t('Record Date')} :</b> <span className="">{moment(Transaction.record_date).format('YYYY-MM-DD')}</span></p>
                <p className="mb-1 border-box-1"><b>{i18next.t('Payment Date')} :</b> <span className="">{moment(Transaction.payment_date).format('YYYY-MM-DD')}</span></p>
                <p className="mb-1 border-box-1"><b>{i18next.t('Number of Token')} :</b> <span className="">{Transaction.number_of_token}</span></p>
                <p className="mb-1 border-box-1"><b>{i18next.t('Dividend Amount')} :</b> <span className="">${Transaction.dividend_amount}</span></p>
                <p className="mb-1 border-box-1"><b>{i18next.t('Amount to be Paid')} :</b> <span className="">{Transaction.paid_amount !== null? `${Transaction.paid_amount} ${Transaction.paid_amount_currency}` : 'N/A'}</span></p>
                <p className="mb-1 border-box-1"><b>{i18next.t('Payment Method')} :</b> <span className="">{Transaction.payment_method}</span></p>
              </div>
              <div className="col-lg-6">
                <p className="mb-1 border-box-1"><b>{i18next.t('Payment Detail')} :</b></p>
                <p className="mb-1" style={{ whiteSpace: 'pre-line' }}>{Transaction.payment_detail !== null? Transaction.payment_detail : 'N/A'}</p>
                <p className="mb-2 border-box-1 mt-4"><b>{i18next.t('Payment Reference')} :</b></p>
                <p className="mb-1"><span>{Transaction.payment_reference !== null? Transaction.payment_reference : 'N/A'}</span></p>
              </div>
            </div>
          </div>
        </Modal>
      </div>

    </React.Fragment>
  );
};

export default withRouter(Dividends);
