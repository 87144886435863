import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import i18next from 'i18next';


const Navbar = props => {
  const activateParentDropdown = (menuItem) => {
    menuItem.classList.add('active');
  };

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/dashboard"> <i className="bx bx-home-circle me-2"></i> {i18next.t('Menu Dashboard')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/wallet"> <i className="bx bx-wallet-alt me-2"></i> {i18next.t('Menu My Wallet')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/property-details"> <i className="bx bx-shopping-bag me-2"></i> {i18next.t('Menu Browse Property')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/portfolio"> <i className="bx bx-cart-alt me-2"></i> {i18next.t('Menu My Portfolio')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/claimed-fund"> <i className="bx bx-money me-2"></i> {i18next.t('Menu My Claimed Fund')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/mytransaction"> <i className="bx bx-list-ul me-2"></i> {i18next.t('Menu My Transaction')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/Kyc"> <i className="bx bx-user-check me-2"></i> {i18next.t('Menu KYC Verification')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/twofaauthenticator"> <i className="bx bx-key me-2"></i> {i18next.t('Menu 2FA Authenticator')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link arrow-none" to="/dividends"> <i className="bx bx-wallet-alt me-2"></i> {i18next.t('Menu Dividends')}</Link>
                </li>

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
