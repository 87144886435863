import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, Label, FormFeedback, Modal } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import ImageGallery from 'react-image-gallery';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from "../../Utils/Snackbars";
import { checkFileValidation } from "../../Utils/Common";
import useRequests from '../../Utils/useRequests';
import useDecryptedAuth from "../../Utils/useDecryptedAuth";
import i18next from 'i18next';


const PropertyDetails = props => {
  //meta title
  document.title = i18next.t('Property_Detail PG document title');
  const { getRequest, postRequest, getRequestForBinance, postRequestByFormData } = useRequests();
  const decryptedAuth = useDecryptedAuth();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async () => {
    // if(decryptedAuth.kyc_status !== 'Approved') {
    if (process.env.REACT_APP_ENV === 'production') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: i18next.t('This feature is under construction'),
      }));
    }
    else {
      let EuroInvestment = 0;
      if (validation1.values.number_of_tokens !== '' || parseFloat(validation1.values.number_of_tokens) > 0) {
        EuroInvestment = parseFloat(validation1.values.number_of_tokens) * PropertyInfo.token_price;
      }

      if(validation1.values.is_used_euro_wallet) {
        let EuroAvailableBalance = parseFloat(CryptoData.euro_available_balance);
        if (TransactionData.length > 0) {
          let filteredData = TransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.total_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        if (PropertyCryptoTransactionData.length > 0) {
          let filteredData = PropertyCryptoTransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.used_euro_wallet_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        
        if(EuroInvestment > EuroAvailableBalance) {
          validation2.setValues({
            ...validation2.values,
            transfer_euro: EuroInvestment-EuroAvailableBalance,
          });
        }
        else {
          validation2.setValues({
            ...validation2.values,
            transfer_euro: 0,
          });
        }
      }
      else {
        validation2.setValues({
          ...validation2.values,
          transfer_euro: EuroInvestment,
        });
      }
      setStandardModal1(true);
    }
  }

  const [YourInvestment, setYourInvestment] = React.useState(0);
  const [ShareOfTotalEquity, setShareOfTotalEquity] = React.useState(0);
  const handleChangeNumberOfTokens = async (tempNumberOfTokens, tempCurrency) => {
    setYourInvestment(0);
    setShareOfTotalEquity(0);
    if (parseFloat(tempNumberOfTokens) > 0 && tempCurrency !== '') {
      let EuroInvestment = tempNumberOfTokens * PropertyInfo.token_price;
      let TempYourInvestment = EuroInvestment;

      if (tempCurrency === 'BTC' || tempCurrency === 'ETH' || tempCurrency === 'MATIC') {
        let RateData = BinanceRatesData.find(item => item.symbol === tempCurrency + 'EUR');
        TempYourInvestment = EuroInvestment / parseFloat(RateData.price);
        TempYourInvestment = TempYourInvestment.toFixed(8);
      }
      else if (tempCurrency === 'USDT' || tempCurrency === 'USDC') {
        let RateData = BinanceRatesData.find(item => item.symbol === 'EUR' + tempCurrency);
        TempYourInvestment = EuroInvestment * parseFloat(RateData.price);
        TempYourInvestment = TempYourInvestment.toFixed(8);
      }

      setYourInvestment(TempYourInvestment);
      setShareOfTotalEquity(((tempNumberOfTokens / PropertyInfo.total_token) * 100).toFixed(2));
    }
  }

  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      currency: 'BTC',
      number_of_tokens: '',
      is_used_euro_wallet: false,
    },
    validationSchema: Yup.object({
      currency: Yup.string().required(i18next.t('Property_Detail PG Please select currency')),
      number_of_tokens: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Property_Detail PG Number of Tokens is required')),
    }),
    onSubmit: async (values) => {
      // if(decryptedAuth.kyc_status !== 'Approved') {
      if (process.env.REACT_APP_ENV === 'production') {
        setAlertData((AlertData) => ({
          ...AlertData,
          variant: 'error',
          message: i18next.t('This feature is under construction'),
        }));
      }
      else {
        try {
          let EuroInvestment = parseFloat(YourInvestment);
          if (values.currency === 'BTC' || values.currency === 'ETH' || values.currency === 'MATIC') {
            let RateData = BinanceRatesData.find(item => item.symbol === values.currency + 'EUR');
            EuroInvestment = Math.round(EuroInvestment * parseFloat(RateData.price));
          }
          else if (values.currency === 'USDT' || values.currency === 'USDC') {
            let RateData = BinanceRatesData.find(item => item.symbol === 'EUR' + values.currency);
            EuroInvestment = Math.round(EuroInvestment / parseFloat(RateData.price));
          }

          if (parseFloat(EuroInvestment) < parseFloat(PropertyInfo.minimum_investment_allocation)) {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: i18next.t('Property_Detail PG Minimum investment Allocation is required'),
            }));
          }
          else {
            let submit1 = document.getElementById('submit1');
            submit1.disabled = true;
            submit1.textContent = i18next.t('Processing...');

            const jsonpar = JSON.stringify({
              p_id: 1,
              currency: values.currency,
              number_of_tokens: values.number_of_tokens,
              is_used_euro_wallet: values.is_used_euro_wallet,
            });
            const res = await postRequest('property-invest', jsonpar);
            if (res && res.code === 200) {
              if (res.data.length > 0 && res.data !== '') {
                window.open(res.data, '_blank');
              }
              else {
                validation1.setValues({
                  ...validation1.values,
                  currency: 'BTC',
                  number_of_tokens: '',
                  is_used_euro_wallet: false,
                });
                setYourInvestment(0);
                setShareOfTotalEquity(0);
                handleLoadGetBinanceRates();
                handleLoadGetCryptoData();
                handleLoadGetTransactionData();
                setTimeout(() => {
                  handleClickCryptoBalanceRefresh();
                }, 7000);

                setAlertData((AlertData) => ({
                  ...AlertData,
                  variant: 'success',
                  message: res.message,
                }));
              }
            }
            else {
              setAlertData((AlertData) => ({
                ...AlertData,
                variant: 'error',
                message: res.message,
              }));
            }

            submit1.disabled = false;
            submit1.textContent = i18next.t('Invest Now');
          }
        } catch (error) {
          console.error("Error : ", error);
        }
      }
      resetAlertData();
    }
  });

  const [File, setFile] = useState();
  const [FileName, setFileName] = useState('');
  const saveFile = (e) => {
    if((e.target.files).length > 0) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);

      var validationRes = checkFileValidation(e.target.files[0]);
      if (!validationRes.status) {
        setFile();
        setFileName('');

        setAlertData((AlertData) => ({
          ...AlertData,
          variant: 'error',
          message: validationRes.msg,
        }));
        resetAlertData();

        return false;
      }
    }
  };

  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      transfer_euro: 0,
      transaction_information: '',
    },
    validationSchema: Yup.object({
      transaction_information: Yup.string()
        .matches(
          /^[#0-9a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Property_Detail PG Transaction Information is required')),
    }),
    onSubmit: async (values) => {
      try {
        if (validation1.values.number_of_tokens === '' || validation1.values.number_of_tokens === 0) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Number of Tokens is required'),
          }));
        }
        else if (parseFloat(YourInvestment) < parseFloat(PropertyInfo.minimum_investment_allocation)) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Minimum investment Allocation is required'),
          }));
        }
        else if (FileName === '') {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Please select a transaction proof!'),
          }));
        }
        else {
          let submit1 = document.getElementById('submit2');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const formData = new FormData();
          formData.append("p_id", 1);
          formData.append("currency", validation1.values.currency);
          formData.append("number_of_tokens", validation1.values.number_of_tokens);
          formData.append("is_used_euro_wallet", validation1.values.is_used_euro_wallet);
          formData.append("transfer_euro", values.transfer_euro);
          formData.append("transaction_information", values.transaction_information);
          formData.append("file", File);
          formData.append("fileName", FileName);
          const res = await postRequestByFormData('property-invest-by-bank', formData);
          if (res && res.code === 200) {
            validation1.setValues({
              ...validation1.values,
              currency: 'BTC',
              number_of_tokens: '',
              is_used_euro_wallet: false,
            });
            setYourInvestment(0);
            setShareOfTotalEquity(0);
            handleLoadGetBinanceRates();
            handleLoadGetCryptoData();
            handleLoadGetTransactionData();
            setTimeout(() => {
              handleClickCryptoBalanceRefresh();
            }, 7000);

            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'success',
              message: res.message,
            }));
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();

          submit1.disabled = false;
          submit1.textContent = i18next.t('Submit');

          setStandardModal1(false);
        }
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const [BinanceRatesData, setBinanceRatesData] = React.useState([]);
  const handleLoadGetBinanceRates = async () => {
    const res = await getRequestForBinance();
    if (res && res.code === 200) {
      setBinanceRatesData(res.data);
    }
  }

  const [PropertyInfo, setPropertyInfo] = React.useState([]);
  const [PropertyImages, setPropertyImages] = React.useState([]);
  const handleLoadGetPropertyInfo = async () => {
    const jsonpar = JSON.stringify({
      p_id: 1
    });
    const res = await postRequest('get-property-info', jsonpar);
    if (res && res.code === 200) {
      setPropertyInfo(res.data.property);

      const filePath = res.data.url;
      const images = res.data.images;

      let tempArr = [];
      images.forEach(element => {
        const temp = {
          original: `${process.env.REACT_APP_API_URL}${filePath}${element.image}`,
          thumbnail: `${process.env.REACT_APP_API_URL}${filePath}${element.image}`,
        };
        tempArr.push(temp);
      });
      setPropertyImages(tempArr);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  const [CryptoData, setCryptoData] = React.useState([]);
  const handleLoadGetCryptoData = async () => {
    const res = await getRequest('get-crypto-data');
    if (res && res.code === 200) {
      setCryptoData(res.data);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const handleClickCryptoBalanceRefresh = async () => {
    const res = await getRequest('crypto-data-refresh');
    if (res && res.code === 200) {
      setCryptoData(res.data);
    }
  }

  const [TransactionData, setTransactionData] = React.useState([]);
  const handleLoadGetTransactionData = async () => {
    const jsonpar = JSON.stringify({
      currency_type: 'Euro',
    });
    const res = await postRequest('get-withdrawal-transaction-data', jsonpar);
    if (res && res.code === 200) {
      setTransactionData(res.data);
    }
  }
  const [PropertyCryptoTransactionData, setPropertyCryptoTransactionData] = React.useState([]);
  const handleLoadGetPropertyCryptoTransactionData = async () => {
    const res = await getRequest('get-property-crypto-transaction-data');
    if (res && res.code === 200) {
      setPropertyCryptoTransactionData(res.data.transaction);
    }
  }
  const [SiteSettings, setSiteSettings] = React.useState([]);
  const handleLoadGetSiteSettings = async () => {
    const res = await getRequest('get-site-settings');
    if (res && res.code === 200) {
      setSiteSettings(res.data);
    }
  }
  React.useEffect(() => {
    handleLoadGetBinanceRates();
    handleLoadGetPropertyInfo();
    handleLoadGetCryptoData();
    handleLoadGetTransactionData();
    handleLoadGetPropertyCryptoTransactionData();
    handleLoadGetSiteSettings();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid className="PropertyDetails">
          <Row className="mt-2">
            <Col className="col-md-12">
              <div className="fullWidthLink">
                <Link className="back-link" to="/dashboard"><i className="bx bx-left-arrow-alt"></i> {i18next.t('Back')}</Link>
              </div>
              <h5 className="mb-3 mainTitle">{PropertyInfo.property_name}</h5>
              <div className="propert-short-des" dangerouslySetInnerHTML={{ __html: PropertyInfo.property_description }} />
              <p className="location"><i className="bx bx-map-pin"></i> <span>{PropertyInfo.property_address}</span></p>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12">
              <div className="leftBar">
                <Card className="mb-0">
                  <CardBody>
                    <div className="product-detai-imgs">
                      <Row>
                        <div className="wrapper">
                          <ImageGallery thumbnailPosition="left" items={PropertyImages} />
                        </div>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="RightBar">
                <Card className="light-green zoom-in-out-box">
                  <CardBody>
                    <div className="product-detai-imgs">
                      <div className="clearfix"></div>
                      <p className="increments-text">{i18next.t('Property_Detail PG Type a number of tokens you want')}</p>
                      <div className="clearfix"></div>
                      <div className="AvailableBox fullWidth">
                        <div className="mb-3 right-selectbox">
                          <div className="leftBox">
                            <select className="form-select" name="currency" value={validation1.values.currency}
                              onChange={(e) => {
                                validation1.setValues({ ...validation1.values, currency: e.target.value });
                                handleChangeNumberOfTokens(validation1.values.number_of_tokens, e.target.value);
                              }}
                            >
                              <option value="">{i18next.t('Select currency')}</option>
                              <option value="BTC">BTC</option>
                              <option value="ETH">ETH</option>
                              <option value="MATIC">MATIC</option>
                              <option value="USDT">USDT-ERC20</option>
                              <option value="USDC">USDC-ERC20</option>
                              <option value="Euro">Euro Bank Transfer</option>
                            </select>
                            {validation1.touched.currency && validation1.errors.currency ? (
                              <FormFeedback type="invalid">{validation1.errors.currency}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="rightBox">
                            <Input
                              name="number_of_tokens"
                              type="text"
                              placeholder={'Enter number of tokens'}
                              onChange={(e) => {
                                validation1.handleChange(e);
                                handleChangeNumberOfTokens(e.target.value, validation1.values.currency);
                              }}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.number_of_tokens || ""}
                              invalid={
                                validation1.touched.number_of_tokens && validation1.errors.number_of_tokens ? true : false
                              }
                            />
                            {validation1.touched.number_of_tokens && validation1.errors.number_of_tokens ? (
                              <FormFeedback type="invalid">{validation1.errors.number_of_tokens}</FormFeedback>
                            ) : null}
                          </div>

                        </div>
                        <div className="clearfix"></div>
                        {/* {validation1.values.currency === 'Euro'? */}
                        <div className="form-check mb-3">
                          <input type="checkbox" className="form-check-input" id="is_used_euro_wallet" name="is_used_euro_wallet" checked={validation1.values.is_used_euro_wallet || false} onClick={(e) => validation1.setValues({ ...validation1.values, is_used_euro_wallet: !validation1.values.is_used_euro_wallet })} />
                          <label className="form-check-label" htmlFor="is_used_euro_wallet"> Euro {i18next.t('Wallet Balance')}: <b>€{CryptoData.euro_available_balance}</b> </label>
                        </div>
                        {/* : ''} */}
                      </div>
                      {validation1.values.currency !== 'Euro'?
                        <Button
                          id="submit1"
                          onClick={(e) => {
                            e.preventDefault();
                            validation1.handleSubmit();
                            return false;
                          }}
                          color="primary" type="button" className="ExpressInterest">{i18next.t('Invest Now')}</Button>
                      :
                        <Button color="primary" type="button" className="ExpressInterest" onClick={(e) => togStandardModal1()}>{i18next.t('Upload Transaction Information')}</Button>
                      }
                      <div className="total-investment">
                        {validation1.values.currency !== '' ?
                          <>
                            <p className="total-purchase-text">{i18next.t('Your investment')}</p>
                            <p className="total-purchase">{YourInvestment} {validation1.values.currency}</p>
                          </>
                          : ''}
                      </div>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Minimum investment Allocation')} : <b>{PropertyInfo.minimum_investment_allocation} EUR</b></p>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Available number of Tokens')} : <b>{PropertyInfo.total_token}</b></p>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Share of total equity')} : <b>{ShareOfTotalEquity}%</b></p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container >

        <div className="clearfix"></div>

        <Container fluid>
          <div className="contentt fullWidth">
            <Card>
              <CardBody>
                <h5 className="property-inner-title">{i18next.t('Property Features')} :</h5>
                <Col className="col-md-12">
                  <div className="product-detai-imgs">
                    <div className="">
                      <div className="row">
                        <div className="col-lg-4 col-md-6 mb-3">
                          <p className="text-capitalize mb-0 PropertyHead">{i18next.t('Furnishing')} : <span>{PropertyInfo.furnishing}</span></p>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                          <p className="text-capitalize mb-0 PropertyHead">{i18next.t('Year Built')} : <span>{PropertyInfo.year_built}</span></p>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                          <p className="text-capitalize mb-0 PropertyHead">{i18next.t('Floor')} : <span>{PropertyInfo.floor}</span></p>
                        </div>
                      </div>
                      <div className="row">

                        <div className="col-lg-4 col-md-6 mb-3">
                          <p className="text-capitalize mb-0 PropertyHead">{i18next.t('Renovation')} : <span>{PropertyInfo.renovation}</span></p>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                          <p className="text-capitalize mb-0 PropertyHead">{i18next.t('Ceiling Height')} : <span>{PropertyInfo.pf_ceiling_height}</span></p>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                          <p className="text-capitalize mb-0 PropertyHead">{i18next.t('Property Type')} : <span>{PropertyInfo.property_type}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </CardBody>
            </Card>

            <Card>
              <CardBody>

                <h5 className="property-inner-title mb-3">{i18next.t('Amenities')} :</h5>
                <p>{i18next.t('Property_Detail PG P1')}</p>
                <Row>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.ac_heating === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('A/C & Heating')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.garages === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Garages')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.garden === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Garden')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.disabled_access === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Disabled Access')}</span></p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.swimming_pool === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Swimming Pool')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.parking === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Parking')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.wifi === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Wifi')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.pet_friendly === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Pet Friendly')}</span></p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.ceiling_height === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Ceiling Height')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.fireplace === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Fireplace')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.play_ground === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Play Ground')}</span></p>
                  </Col>
                  <Col lg={3} className="col-md-6">
                    <p className="Check-amenities"><i className={PropertyInfo.elevator === 1 ? 'bx bx-check' : 'bx bx-x'}></i> <span>{i18next.t('Elevator')}</span></p>
                  </Col>
                </Row>

              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className="mt-3">
                  <h5 className="property-inner-title mb-3">{i18next.t('Investment Highlights')} :</h5>
                  <Row className="mb-3">
                    <div dangerouslySetInnerHTML={{ __html: PropertyInfo.investment_highlights }} />
                  </Row>
                </div>

              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className="mt-3">
                  <h5 className="property-inner-title mb-3">{i18next.t('Location')} :</h5>
                </div>
                <iframe title="Google Map" className="googleMap" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2983.765655116769!2d2.4073345!3d41.5959522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4cbe841e48509%3A0xb2ef304dae952d28!2sCarrer%20Pau%20Picasso%2C%2031%2C%20Bajos%20D%2C%2008319%20Dosrius%2C%20Barcelona%2C%20Spain!5e0!3m2!1sen!2sin!4v1717156338322!5m2!1sen!2sin" style={{ border: 0 }} aria-hidden="false" tabIndex="0"></iframe>
              </CardBody>
            </Card>
          </div>
        </Container>


        <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" size="xl">
          <div className="modal-header">
            <h5 className="modal-title mt-0">{i18next.t('Upload Transaction Information')}</h5>
            <button type="button" onClick={(e) => setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-lg-6">
              <div className="bgBankInfo">
                <p className="ShareAllocation ShareAllocation-details mb-3">{i18next.t('Property_Detail PG Please transfer')} <b>{validation2.values.transfer_euro} EUR</b> {i18next.t('Property_Detail PG to below account')}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Beneficiary Name')} :</b> {SiteSettings.beneficiary_name}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Bank Name')} :</b> {SiteSettings.bank_name}</p>
                <p className="accountInfoDetails"><b>{i18next.t('IBAN')} :</b> {SiteSettings.iban_no}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Routing Number')} :</b> {SiteSettings.routing_number}</p>
                <p className="accountInfoDetails"><b>{i18next.t('SWIFT Code')} :</b> {SiteSettings.swift_code}</p>
                <div className="uploaddocument">
                  <CopyToClipboard text={`${i18next.t('Beneficiary Name')} : ${SiteSettings.beneficiary_name}
${i18next.t('Bank Name')} : ${SiteSettings.bank_name}
${i18next.t('IBAN')} : ${SiteSettings.iban_no}
${i18next.t('Routing Number')} : ${SiteSettings.routing_number}
${i18next.t('SWIFT Code')} : ${SiteSettings.swift_code}`}>
                    <Button color="primary" className="ExpressInterest btn btn-light">{i18next.t('Property_Detail PG Copy Bank Details')}</Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bgBankInfo bg-white">
                <div className="mb-3">
                  <Label className="form-label">{i18next.t('Property_Detail PG Transaction Information')}</Label>
                  <Input type="text"
                    name="transaction_information"
                    placeholder={i18next.t('Property_Detail PG Please Enter Transaction Information')}
                    onChange={validation2.handleChange}
                    onBlur={validation2.handleBlur}
                    value={validation2.values.transaction_information || ""}
                  />
                  {validation2.touched.transaction_information && validation2.errors.transaction_information ? (
                    <FormFeedback type="invalid">{validation2.errors.transaction_information}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{i18next.t('Property_Detail PG Upload Transaction Proof')}</Label>
                  <Input type="file" onChange={saveFile}/>
                </div>
                <Button color="primary" className="ExpressInterest mt-0"
                  id="submit2"
                  onClick={(e) => {
                    e.preventDefault();
                    validation2.handleSubmit();
                    return false;
                  }}
                >Submit</Button>
              </div>
            </div>
          </div>
        </Modal>
      </div >
    </React.Fragment >
  )
}

PropertyDetails.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default PropertyDetails
