import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter, useLocation } from "react-router-dom"

import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import i18next from 'i18next';


const Logout = props => {
  const dispatch = useDispatch();
	const location = useLocation();

  useEffect(() => {
    let locationState;
    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      locationState = { code: location.state.code, status: location.state.status, message: location.state.message }
		}
    else {
      locationState = { code: 200, status: 'success', message: i18next.t('Login PG You have successfully logged out') }
    }
    dispatch(logoutUser(props.history, locationState))
  }, [dispatch, location])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
